<template>
  <el-tabs v-model="activeNavs" type="card" @tab-click="changeNavs">
    <el-tab-pane v-for="(item, index) in navs" :key="index" :name="item" :label="item">
    </el-tab-pane>
  </el-tabs>
  <lidaPage v-if="activeNavs !== '全部供应商'" ref="lidaPage" :columns="tableHead" :query="enterpriseMyEnterprisePage"
    :formColumns="formColumns" :queryInfo="queryInfo" :showResetBtn="false" subTitle="总供应商数">
    <template #authFlag="scope">
      <div>
        <!--        {{scope.row.authFlag=="0"&&scope.row.proprietaryFlag=="1"||scope.row.authFlag==""?'自营标识':scope.row.authFlag=="*"||scope.row.proprietaryFlag=="*"?'*':scope.row.authFlag=="1"&&scope.row.proprietaryFlag=="0"||scope.row.proprietaryFlag==""?'认证标识':'无标识'}}-->
        <!-- <span v-if="scope.row.agentList&&scope.row.agentList.length" class="isAgent-text">Ⅴ</span> -->
        <el-tag v-if="scope.row.isAgent" size="mini" style="margin-right: 10px">
          {{ scope.row.isAgent ? "代理" : "" }}
        </el-tag>
        <el-tag v-if="scope.row.proprietaryFlag === '1'" type="success" size="mini" style="margin-right: 10px">
          {{ scope.row.proprietaryFlag === "1" ? "自营" : "" }}
        </el-tag>
        <el-tag v-if="scope.row.authFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
          scope.row.authFlag === "1" ? "认证" : "" }}
        </el-tag>
        <el-tag v-if="scope.row.manufactorFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
          scope.row.manufactorFlag === "1" ? "厂家" : "" }}
        </el-tag>
        <!--				 <el-popover-->
        <!--              v-if="scope.row.agentList&&scope.row.agentList.length"-->
        <!--              placement="right"-->
        <!--              width="400"-->
        <!--              trigger="click"-->
        <!--            >-->
        <!--              <div class="h-tree-box">-->
        <!--                <p class="h-title">代理品牌</p>-->
        <!--                &lt;!&ndash; <el-tree-->
        <!--                  :data="item.agentList"-->
        <!--                  default-expand-all-->
        <!--                  node-key="brandName"-->
        <!--                  ref="tree"-->
        <!--                /> &ndash;&gt;-->
        <!--                <p v-for="(v,i) in scope.row.agentList" :key="i">{{v.brandName}}</p>-->
        <!--              </div>-->
        <!--              <template #reference>-->
        <!--                <span v-if="scope.row.agentList&&scope.row.agentList.length" class="isAgent-text">Ⅴ</span>-->
        <!--              </template>-->
        <!--            </el-popover>-->
      </div>
    </template>
    <template #enterpriseName="scope">
      <div>
        {{ scope.row.enterpriseName || "" }}
        <div style="margin: 10px 0">
          <el-tag v-if="scope.row.isAgent" size="mini" style="margin-right: 10px">
            {{ scope.row.isAgent ? "代理" : "" }}
          </el-tag>
          <el-tag v-if="scope.row.proprietaryFlag === '1'" type="success" size="mini" style="margin-right: 10px">
            {{ scope.row.proprietaryFlag === "1" ? "自营" : "" }}
          </el-tag>
          <el-tag v-if="scope.row.authFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
            scope.row.authFlag === "1" ? "认证" : "" }}
          </el-tag>
          <el-tag v-if="scope.row.manufactorFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
            scope.row.manufactorFlag === "1" ? "厂家" : "" }}
          </el-tag>
        </div>
        <!-- <el-popover
				v-if="scope.row.agentList&&scope.row.agentList.length"
				placement="right"
				width="400"
				trigger="click">
				<div class="tree-box">
					<p class="h-title">代理品牌</p>
					<el-tree
						:data="treeList"
						default-expand-all
						node-key="id"
						ref="tree"
					/>
				</div>
				<template #reference>
					<span v-if="scope.row.agentList&&scope.row.agentList.length" class="isAgent-text">Ⅴ</span>
				</template>
				</el-popover> -->
      </div>
    </template>
    <template #startBusinessTime="scope">
      <div>
        {{
          (scope.row.startBusinessTime || "") +
          "-" +
          (scope.row.endBusinessTime || "")
        }}
      </div>
    </template>
    <template #agentList="scope">
      <div v-for="(v, i) in scope.row.agentList" :key="i">
        {{ v.brandName }}
      </div>
    </template>
    <template #enterpriseStatus="scope">
      <div>{{ scope.row.enterpriseStatus === "1" ? "禁用" : "启用" }}</div>
    </template>
    <template #annualTurnover="scope">
      <div>￥{{ scope.row.annualTurnover }}</div>
    </template>
    <template #operation>
      <lida-table-column label="操作" width="50" fixed="right">
        <template #default="scope">
          <el-button @click="commands(scope.row)" type="text">查看</el-button>
        </template>
      </lida-table-column>
    </template>
  </lidaPage>

  <lidaPage1 v-else ref="lidaPage1" :columns="tableHead" :query="allEnterprisePage" :formColumns="formColumns"
    :queryInfo="queryInfo" :showResetBtn="false" subTitle="总供应商数">
    <template #authFlag="scope">
      <div>
        <!--        {{scope.row.authFlag=="0"&&scope.row.proprietaryFlag=="1"||scope.row.authFlag==""?'自营标识':scope.row.authFlag=="*"||scope.row.proprietaryFlag=="*"?'*':scope.row.authFlag=="1"&&scope.row.proprietaryFlag=="0"||scope.row.proprietaryFlag==""?'认证标识':'无标识'}}-->
        <!-- <span v-if="scope.row.agentList&&scope.row.agentList.length" class="isAgent-text">Ⅴ</span> -->
        <el-tag v-if="scope.row.isAgent" size="mini" style="margin-right: 10px">
          {{ scope.row.isAgent ? "代理" : "" }}
        </el-tag>
        <el-tag v-if="scope.row.proprietaryFlag === '1'" type="success" size="mini" style="margin-right: 10px">
          {{ scope.row.proprietaryFlag === "1" ? "自营" : "" }}
        </el-tag>
        <el-tag v-if="scope.row.authFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
          scope.row.authFlag === "1" ? "认证" : "" }}
        </el-tag>
        <el-tag v-if="scope.row.manufactorFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
          scope.row.manufactorFlag === "1" ? "厂家" : "" }}
        </el-tag>
        <!--				 <el-popover-->
        <!--              v-if="scope.row.agentList&&scope.row.agentList.length"-->
        <!--              placement="right"-->
        <!--              width="400"-->
        <!--              trigger="click"-->
        <!--            >-->
        <!--              <div class="h-tree-box">-->
        <!--                <p class="h-title">代理品牌</p>-->
        <!--                &lt;!&ndash; <el-tree-->
        <!--                  :data="item.agentList"-->
        <!--                  default-expand-all-->
        <!--                  node-key="brandName"-->
        <!--                  ref="tree"-->
        <!--                /> &ndash;&gt;-->
        <!--                <p v-for="(v,i) in scope.row.agentList" :key="i">{{v.brandName}}</p>-->
        <!--              </div>-->
        <!--              <template #reference>-->
        <!--                <span v-if="scope.row.agentList&&scope.row.agentList.length" class="isAgent-text">Ⅴ</span>-->
        <!--              </template>-->
        <!--            </el-popover>-->
      </div>
    </template>
    <template #enterpriseName="scope">
      <div>
        {{ scope.row.enterpriseName || "" }}
        <div style="margin: 10px 0">
          <el-tag v-if="scope.row.isAgent" size="mini" style="margin-right: 10px">
            {{ scope.row.isAgent ? "代理" : "" }}
          </el-tag>
          <el-tag v-if="scope.row.proprietaryFlag === '1'" type="success" size="mini" style="margin-right: 10px">
            {{ scope.row.proprietaryFlag === "1" ? "自营" : "" }}
          </el-tag>
          <el-tag v-if="scope.row.authFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
            scope.row.authFlag === "1" ? "认证" : "" }}
          </el-tag>
          <el-tag v-if="scope.row.manufactorFlag === '1'" type="warning" size="mini" style="margin-right: 10px">{{
            scope.row.manufactorFlag === "1" ? "厂家" : "" }}
          </el-tag>
        </div>
        <!-- <el-popover
				v-if="scope.row.agentList&&scope.row.agentList.length"
				placement="right"
				width="400"
				trigger="click">
				<div class="tree-box">
					<p class="h-title">代理品牌</p>
					<el-tree
						:data="treeList"
						default-expand-all
						node-key="id"
						ref="tree"
					/>
				</div>
				<template #reference>
					<span v-if="scope.row.agentList&&scope.row.agentList.length" class="isAgent-text">Ⅴ</span>
				</template>
				</el-popover> -->
      </div>
    </template>
    <template #startBusinessTime="scope">
      <div>
        {{
          (scope.row.startBusinessTime || "") +
          "-" +
          (scope.row.endBusinessTime || "")
        }}
      </div>
    </template>
    <template #agentList="scope">
      <div v-for="(v, i) in scope.row.agentList" :key="i">
        {{ v.brandName }}
      </div>
    </template>
    <template #enterpriseStatus="scope">
      <div>{{ scope.row.enterpriseStatus === "1" ? "禁用" : "启用" }}</div>
    </template>
    <template #annualTurnover="scope">
      <div>￥{{ scope.row.annualTurnover }}</div>
    </template>
    <template #operation>
      <lida-table-column label="操作" width="50" fixed="right">
        <template #default="scope">
          <el-button @click="commands(scope.row)" type="text">查看</el-button>
        </template>
      </lida-table-column>
    </template>
  </lidaPage1>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  handleTreeData, //处理树形数据
} from "@/common/js/common.js";
import lidaPage1 from '../../../components/publicComponent/lidaPage1.vue'
export default {

  components: {
    lidaPage1
  },
  data() {
    return {

      queryInfo: {},
      activeNavs: "我的供应商",
      navs: ["我的供应商", "邀请供应商", "绑定供应商", "全部供应商"],
      tableHead: [
        // 表格头部
        // {label: '企业标识',prop: 'authFlag'},
        { label: "公司名称", prop: "enterpriseName" },
        { label: "公司ID", prop: "enterpriseCode", width: "300" },

        // {label: '标签',prop: 'authFlag'},
        { label: "注册时间", prop: "establishedTime" },
        { label: "地址", prop: "address" },
        { label: "代理品牌", prop: "agentList" },
        { label: "经营品牌", prop: "agentBrand" },
        { label: "法人", prop: "legalPersonName" },
        { label: "联系人", prop: "legalPersonName" },
        { label: "电话", prop: "legalPersonPhone" },
        // {label: '企业统一社会信用编码',prop: 'socialCreditCode'},
        // {label: '企业类型',prop: 'enterpriseType'},
        // {label: '行政编码',prop: 'enterpriseNationalCode'},
        // {label: '营业期限',prop: 'businessTerm'},
        // {label: '代理品牌',prop: 'agentBrand'},
        // {label: '经营范围',prop: 'businessScope'},
        // {label: '企业注册地址',prop: 'enterpriseAddr'},
        // {label: '企业状态',prop: 'enterpriseStatus'},
        // {label: '企业类型',prop: 'enterpriseOcrType'},
        // {label: '网址',prop: 'website'},
        // {label: '办公区域/详细地址',prop: 'address'},
        // {label: '电话',prop: 'legalPersonName'},
        // {label: '传真',prop: 'fax'},
        // {label: '员工人数',prop: 'staffNum'},
        // {label: '年营业额',prop: 'annualTurnover'},
      ],
      formColumns: [
        {
          type: "input",
          prop: "keyword",
          label: "综合搜索",
          placeholder: "请输入名字、联系人、法人等关键字",
        },
      ],
      treeList: [],
    };
  },
  computed: {
    ...mapState("purchaseMudule", [
      "paymentTypes", //付款方式
    ]),
  },
  created() {
    this.getTreeList();
  },
  methods: {
    ...mapActions("supplierModule", [
      "enterpriseMyEnterprisePage", //我的供应商列表
      "allEnterprisePage", //所有
    ]),
    ...mapActions("mysaleMudule", [
      "listTreeReq", //获取左边tree数据
      // 'quoteSetUpdate',  //修改非代理设置
    ]),
    changeNavs(v) {
      if (this.activeNavs === "我的供应商") {

        delete this.queryInfo.shareRegisterFlag;
        this.$refs.lidaPage.refresh();

      } else if (this.activeNavs === "邀请供应商") {

        this.queryInfo.shareRegisterFlag = "1";
        this.$refs.lidaPage.refresh();

      } else if (this.activeNavs === "绑定供应商") {

        this.queryInfo.shareRegisterFlag = "0";
        this.$refs.lidaPage.refresh();

      } else {

        delete this.queryInfo.shareRegisterFlag;
        this.$refs.lidaPage1.refresh();

      }

    },
    commands(row) {
      //操作的点击事件
      this.$router.push({
        //跳转到详情页
        path: "/supplier/enterpriseDetails",
        query: {
          id: row.id,
        },
      });
    },
    getTreeList() {
      this.listTreeReq({ type: "DIS_AGENT" }).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {
          this.treeList = handleTreeData(data, "categoryName");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.isAgent-text {
  color: #d84146;
  font-weight: 900;
  cursor: pointer;
}

.tree-box {
  height: 350px;
  overflow-y: auto;

  .h-title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 15px;
    border-radius: 5px;
    background: #f2f5fa;
  }

  .h-tree-box {
    height: 350px;
    overflow-y: auto;

    .h-title {
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 15px;
      border-radius: 5px;
      background: #f2f5fa;
    }
  }
}
</style>
